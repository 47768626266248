/*! Copyright 2025 Lane Crawford, All Rights Reserved. */

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.hero-banner__link,
.new-in-block__link,
.trading-box__link,
.discover-block__link {
  background-color: rgba(0, 0, 0, 0.001);
}

.hero-banner__img.is-loading {
  padding-top: 70%;
  height: 0;
}

@media print, screen and (min-width: 48em) {
  .hero-banner__img.is-loading {
    padding-top: 33%;
  }
}

.hero-banner__img.is-flipped {
  transform: scaleX(-1);
}

.hero-banner__content {
  transform: translateY(-1.75rem);
}

@media print, screen and (min-width: 48em) {
  .hero-banner__shiv {
    top: 50%;
    transform: translateY(-50%);
  }
}

.hero-banner__button, .hero-banner__button.btn--alt {
  color: #000;
  border-color: currentColor;
  background-color: transparent;
  min-width: 11.25rem;
  height: 2.8rem;
  line-height: 2.7rem;
}

.hero-banner:hover .hero-banner__button,
.hero-banner:hover .hero-banner__button.btn--alt {
  color: #000;
  border-color: #000;
  background-color: transparent;
}

@media print, screen and (min-width: 48em) {
  .hero-banner__button.btn--alt {
    color: #fff;
    border-color: #fff;
  }
  .hero-banner:hover .hero-banner__button {
    color: #fff;
    border-color: #000;
    background-color: #000;
  }
  .hero-banner:hover .hero-banner__button.btn--alt {
    color: #000;
    border-color: #fff;
    background-color: #fff;
  }
}

.trading-box__img.js-lazy {
  padding-top: 100%;
  height: 0;
}

.discover-block__img.js-lazy {
  padding-top: 62%;
  height: 0;
}

.new-in-block__img.is-loading {
  padding-top: 65%;
  height: 0;
}

@media screen and (min-width: 64em) {
  .new-in-block__img.is-loading {
    padding-top: 77%;
  }
}

.discover-landing__prev-spinner {
  transition: all 0.33s cubic-bezier(0.43, 0, 0.36, 1);
}

.discover-landing__prev-spinner:not(.is-active) {
  margin-top: 0;
  margin-bottom: 0;
  height: 0;
}
